import * as React from 'react';
import { IInvestment, InvestmentContextType } from '@/@types/investment';
import Cookies from 'js-cookie';

export const InvestmentContext = React.createContext<
    InvestmentContextType | any
>(null);

interface Props {
    children: React.ReactNode;
}

const InvestmentProvider: React.FC<Props> = ({ children }) => {
    const [investment, setInvestment] = React.useState<IInvestment | null>(
        null,
    );
    const [hasApplyWallet, setHasApplyWallet] = React.useState<boolean>(false);

    const handleUpdate = (DataToken: IInvestment | null) => {
        if (DataToken) {
            setInvestment(DataToken);
        } else {
            setInvestment(null);
        }
    };

    const handleCancel = () => {
        setInvestment(null);
    };

    const handleApplyWallet = () => { 
        Cookies.set('wallet', "true");
        setHasApplyWallet(true);
    }

    const verifyInvestment = () => {
        if (investment) {
            investment.verified = true;
        }
        setInvestment(investment);
    }

    const data = {
        investment,
        hasApplyWallet,
        handleApplyWallet,
        verifyInvestment,
        handleUpdate,
        handleCancel,
    };

    return (
        <InvestmentContext.Provider value={data}>
            {children}
        </InvestmentContext.Provider>
    );
};

export default InvestmentProvider;
